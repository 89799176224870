import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import coinBox from "../../assets/coin_gift_box.png"
import ButtonAnimate from '../ButtonAnimate/ButtonAnimate';


function Header(props) {
    return (
        <Stack
            height="40px"
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ borderRadius: "4px", backgroundColor: "#D9D9D9" }}
            width={"inherit"}
        >
            <Typography
                fontFamily="Inter"
                fontWeight="700"
                fontSize="20px"
                lineHeight="20px"
                align="center"
                color="#2D2D2D"
                paddingX={"20px"}
            >
                RYN Cricket Daily Quiz
            </Typography>
        </Stack>
    );
}

const TermsComp = () => {
    return (<>
        <Stack direction={"column"} alignItems={"center"} >
            <Header />
            <Box
                display={"flex"}
                flexDirection={"column"}
                width={"inherit"}
                sx={{
                    position: "static",
                    transform: "translateY(-20px)",
                    border: "5.56px solid rgba(255, 255, 255, 0.02)",
                    borderRadius: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    zIndex: 0,
                }}
            >
                <Stack sx={{ m: 4, pt: 2 }} direction="column" justifyContent="center" alignItems={"center"}>
                    <img src={coinBox} alt={""} width="87px" />
                    <Typography variant="h3" margin={"3px"} align='center' gutterBottom sx={{ color: "#FFF", fontFamily: "Inter", fontSize: "28px", fontStyle: "normal", fontWeight: "500", lineHeight: "normal" }}>
                        Answer 2 Questions Daily
                    </Typography>
                    <Typography variant="h3" margin={"3px"} padding={"2px"} align='center' gutterBottom sx={{ color: "#FFF", fontFamily: "Roboto", fontSize: "20px", fontStyle: "normal", fontWeight: "200", lineHeight: "normal" }}>
                        & Win Exciting Prices with a Guaranteed Scratch Card
                    </Typography>
                    <Box padding={1} width={"100%"}>
                        <Box width={"100%"} >
                            <Typography variant="h3"   gutterBottom sx={{ color: "#FFF", fontFamily: "Roboto", fontSize: "15px", fontStyle: "regular", fontWeight: "800", lineHeight: "normal" }}>
                                Rules
                            </Typography>
                        </Box>
                        <Box>
                            <ol style={{ color: "whitesmoke" }}>
                                <li style={{ fontFamily: "Roboto", fontWeight: "100", fontSize: "13px", lineHeight: "200%" }}>You have to answer both questions in order to be eligible for the prize</li>
                                <li style={{ fontFamily: "Roboto", fontWeight: "100", fontSize: "13px", lineHeight: "200%" }}>Daily winners will be announced after the match</li>
                                <li style={{ fontFamily: "Roboto", fontWeight: "100", fontSize: "13px", lineHeight: "200%" }}>Winners will be contacted via WhatsApp number provided by the user</li>
                                {/* <li style={{ fontFamily: "Roboto", fontWeight: "100", fontSize: "13px", lineHeight: "200%" }}>Bake for 20 minutes.</li> */}
                            </ol>
                        </Box>

                    </Box>

                </Stack>
            </Box>

        </Stack>
        <Stack flexGrow="1"></Stack>
        <Stack direction={"row"}
        sx={{
            justifyContent: "center",
            marginBottom:"30px"
        }}
        >
            <ButtonAnimate text={"Start Quiz"}/>
        </Stack>
    </>
    );
}

export default TermsComp;

