import * as React from 'react';
import Box from '@mui/material/Box';
import StartQuiz from './pages/StartQuiz';
import QuestionPage from './pages/QuestionPage';
import UserFormPage from './pages/UserFormPage';
import ScratchCardPage from './pages/ScratchCardPage';
import {
  // BrowserRouter as Router,
  Routes,
  Route,
  // useNavigate,
} from "react-router-dom";
import JsonContext from './context/JsonContext';
// import jsondata from "./database/database.json"
import axios from "axios";
import { initializeApp } from "firebase/app";
import firebaseConfig from './database/firebase';
import { getValue } from "firebase/remote-config";
import { getRemoteConfig } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import {addCustomToOptions} from "./utilities/general"

export default function App() {

  const {setJsonData} = React.useContext(JsonContext)

React.useEffect(() => {
  const firebase = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(firebase);
  remoteConfig.settings.minimumFetchIntervalMillis = 30000;


  fetchAndActivate(remoteConfig)
    .then(() => {
    const avatar1 = getValue(remoteConfig, "avatar1")._value;
    const avatar2 = getValue(remoteConfig, "avatar2")._value;
    const questions = JSON.parse(getValue(remoteConfig, "questions")._value);
    const coupons = JSON.parse(getValue(remoteConfig, "coupons")._value);
    const c_fx_url = getValue(remoteConfig, "c_fx_url")._value;

    questions.map(addCustomToOptions)

    setJsonData({
      "avatar1": avatar1,
      "avatar2": avatar2,
      "questions": questions,
      "coupons": coupons,
      "c_fx_url":c_fx_url
  })

    })
    .catch((err) => {
      alert("server error \n Thank you")
      window.location.reload();
      setTimeout(() => {},5000)
    });

},[]);

  
React.useEffect(() => {
  const disableBack = (e) => {
    e.preventDefault();
    window.history.forward();
  };

  window.addEventListener('popstate', disableBack);

  return () => {
    window.removeEventListener('popstate', disableBack);
  };
}, []);

  return (
    < Box
      sx={{ 
        width: "100vw", minHeight: "100vh", backgroundColor: "#222222" ,
        display: "flex",
      }}
    >
    <Routes>
            <Route exact path="/" element={<StartQuiz/>} />
            <Route path="/quiz" element={<QuestionPage/>} />
            <Route path="/userform" element={<UserFormPage/>}/>
            <Route path="/scratchcard" element={<ScratchCardPage/>} />
            <Route path="/*" element={<StartQuiz/>} />
    </Routes>
      
    </Box>
  );
}
