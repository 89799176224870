import React from "react";
import "./ButtonAnimate.css";
import { useNavigate } from "react-router-dom";
import ResponseContext from "../../context/responseContext";
import alertContext from "../../context/alertContext";
import { isValidEmail, isValidPhoneNumber } from "../../utilities/general";
const ButtonAnimate = (props) => {
  const { responseData,setResponseData } = React.useContext(ResponseContext);
  const { setAlert } = React.useContext(alertContext);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="container"
        onClick={(event) => {
          event.preventDefault();
          switch (props.text) {
            case "Start Quiz":
              navigate("/quiz");
              break;
            case "Next":
              switch (props.questionNo) {
                case 0:
                  if (responseData?.questions?.["0"]?.id !== "") {
                    props.setQuestionNo(1);
                  } else {
                    setAlert({
                      status: true,
                      message: "Please complete quiz 1",
                    });
                  }
                  break;
                case 1:
                  if (responseData?.questions?.["1"]?.id !== "") {
                    navigate("/userform");
                  } else {
                    setAlert({
                      status: true,
                      message: "Please complete quiz 2",
                    });
                  }
                  break;
                default:
                  navigate("/userform");
                  break;
              }
              break;
            case "Submit":
              if (props.name === "") {
                setAlert({
                  status: true,
                  message: "Name invalid",
                });
              } else if (!isValidEmail(props.email)) {
                setAlert({
                  status: true,
                  message: "Email not valid",
                });
              } else if (!isValidPhoneNumber(props.no)) {
                setAlert({
                  status: true,
                  message: "Whatsapp no not valid",
                });
              } else if (!props.check) {
                setAlert({
                  status: true,
                  message: "Please agree T&C",
                });
              } else {
                setResponseData((d)=>{
                  return({
                    ...d,
                    "user":{
                      name:props.name,
                      email:props.email,
                      whatsapp_no : props.no
                    }
                  })
                })
                navigate("/scratchcard");
              }
              break;
            default:
              navigate("/");
          }
        }}
      >
        <div className="btn-alt">{props.text}</div>
      </div>
    </>
  );
};

export default ButtonAnimate;
