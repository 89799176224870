import { Box } from '@mui/material'
import React from 'react'


const AvatarMatch = (props) => {
  return (
    <>
        <Box 
        component="img"
        src={props.imgUrl}
        boxShadow={2}
        sx={{
            borderRadius:"50%",
            width:"100px",
            height:"100px",
            backgroundColor:"#D9D9D9",
            objectFit: "cover"
        }}
        
        >
        {/* <img src={} alt={}></img> */}
        </Box>
    </>
  )
}

export default AvatarMatch