import { parsePhoneNumberFromString, getCountryCallingCode } from 'libphonenumber-js';
function selectRandomItems(items, count) {
    const selectedItems = [];

    while (selectedItems.length < count) {
        const randomIndex = Math.floor(Math.random() * items.length);
        const selectedItem = items[randomIndex];

        // Make sure we don't select the same item twice
        if (!selectedItems.includes(selectedItem)) {
            selectedItems.push(selectedItem);
        }
    }

    return selectedItems;
}

function isValidEmail(email) {
  // Regular expression pattern for a valid email address
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Test the email against the pattern
  return emailPattern.test(email);

}
// function isValidPhoneNumber(phoneNumber) {
//   try {
//     let parsedNumber = parsePhoneNumberFromString(phoneNumber);
    
//     // If there's no country code, assume the default country code (e.g., US)
//     if (!parsedNumber) {
//       const defaultCountryCode = getCountryCallingCode("IN");
//       phoneNumber = `+${defaultCountryCode}${phoneNumber}`;
//       parsedNumber = parsePhoneNumberFromString(phoneNumber);
//     }
    
//     return parsedNumber.isValid();
//   } catch (error) {
//     return false;
//   }
// }

function isValidPhoneNumber(phoneNumber) {
  if (phoneNumber === "") {
    return false;
  } else {
    return true;
  }
}

function copyToClipboard(text) {
  // Create a textarea element to temporarily hold the text
  const textarea = document.createElement('textarea');
  textarea.value = text;

  // Append the textarea to the document
  document.body.appendChild(textarea);

  // Select the text in the textarea
  textarea.select();

  // Execute the copy command
  document.execCommand('copy');

  // Remove the textarea
  document.body.removeChild(textarea);
}

function addCustomToOptions(item) {
    const newItem = { ...item }; // Create a shallow copy
    if (!newItem.hasOwnProperty("options")) {
        newItem.options = [];
    }

    // Check if "custom" is not already present in "options"
    const hasCustom = newItem.options.some(option => option.hasOwnProperty("custom"));
    if (!hasCustom) {
        newItem.options.push({ custom: "Any other guess" });
    }

    return newItem;
}

export {selectRandomItems,isValidEmail,isValidPhoneNumber,copyToClipboard,addCustomToOptions}