import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { JsonProvider } from './context/JsonContext';
import { ResponseProvider } from './context/responseContext';
import { AlertProvider } from './context/alertContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>
  <React.StrictMode>

    <Router>
      <JsonProvider>
        <ResponseProvider>
        <AlertProvider>
          <App />
          </AlertProvider>
        </ResponseProvider>
      </JsonProvider>
    </Router>
  </React.StrictMode>

</>
);
