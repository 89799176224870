import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import logo from "../assets/logo.png"
import UserForm from '../components/UserForm/UserForm';
import Alert from '../components/Alert/Alert';

const UserFormPage = () => {
    return (
        <Stack
            direction="column"
            justifyContent="centre"
            alignItems="centre"
            width={"inherit"}
            flexGrow={1}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="centre"
                spacing={2}
                padding="18px"
            >
                {/* <IconButton aria-label="fingerprint" color="secondary">
                    <KeyboardArrowLeftIcon sx={{ color: "whitesmoke", width: "24px", height: "24px", left: "18px", top: "18px" }} />
                </IconButton> */}

            </Stack>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="centre"
                padding="18px"

            >
                <img
                    src={logo}
                    alt=''
                    height={"65.71px"}
                />
            </Stack>
            <Stack direction="column"
            flexGrow={1}
            >
                <UserForm />
            </Stack>
            
        </Stack>
    )
}

export default UserFormPage