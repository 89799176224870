import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import alertContext from "../../context/alertContext";

const Alert = () => {
  const { alert } = React.useContext(alertContext);
  return (
    <>
      <Box display={"block"} paddingBottom={"20px"}>
        <Typography
          variant="h3"
          margin={"3px"}
          
          align="center"
          gutterBottom
          display={alert.status ? "block" : "none"}
          sx={{
            color: "#990000",
            fontFamily: "Inter",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          {alert.message}
        </Typography>
      </Box>
    </>
  );
};

export default Alert;
