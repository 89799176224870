import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CaroComp from "../CaroComp/CaroComp";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import JsonContext from "../../context/JsonContext";
import { selectRandomItems } from "../../utilities/general";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Carousal(props) {
  const { jsonData } = React.useContext(JsonContext);
  let questions = [];
  if (jsonData?.questions) {
    questions = selectRandomItems(jsonData?.questions, 2);
  }
  return (
    <>
      <Box sx={{ width: "inherit" }} >
        {questions?.map((d, i) => {
          return (
            <CustomTabPanel value={props.value} index={i} key={i}>
              <CaroComp obj={d} index={i}/>
            </CustomTabPanel>
          );
        })}
      </Box>
    </>
  );
}
