import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import logo from "../assets/logo.png";
import ScratchCard from "react-scratchcard";
import scratchcard from "../assets/scratchcard.png";
import CouponCard from "../components/CouponCard/CouponCard";
import {selectRandomItems} from "../utilities/general"
import JsonContext from "../context/JsonContext";
import ResponseContext from "../context/responseContext";
import axios from "axios";
const ScratchCardPage = () => {
  const {jsonData} = React.useContext(JsonContext)
  let scratchCardObj=selectRandomItems(jsonData?.coupons ? jsonData?.coupons:[],1)[0]
  const {responseData} = React.useContext(ResponseContext)

  const settings = {
    width: "311px",
    height: "352px",
    image: scratchcard,
    finishPercent: 30,
    onComplete: () => {
      
      console.log('Thank you for participating quiz!')
      let responseData_={
        ...responseData,
        "timeStamp": new Date().toISOString(),
        "offerCode":scratchCardObj?.couponCode
      }
      try {
        axios.post(jsonData?.c_fx_url,responseData_ )
      } catch (error) {
        console.log("error sending data to server");
      }
      
  }}

  // console.log(scratchCardObj);
  return (
    <Stack direction="column" justifyContent="centre" alignItems="centre" flexGrow={1}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="centre"
        spacing={2}
        padding="18px"
      >
        {/* <IconButton aria-label="fingerprint" color="secondary">
                    <KeyboardArrowLeftIcon sx={{ color: "whitesmoke", width: "24px", height: "24px", left: "18px", top: "18px" }} />
                </IconButton> */}
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="centre"
        padding="18px"
      >
        <img src={logo} alt="" height={"65.71px"} />
      </Stack>
      <Stack direction="column" margin="30px">

        <Stack direction={"row"} justifyContent={"center"} paddingY={"70px"}>
          <ScratchCard {...settings}>
            <Box variant="img" width={"311px"} height={"352px"}>
              {/* <img className="" alt="" src={scratchreveal} /> */}
              <CouponCard scratchCardObj={scratchCardObj}/> 
            </Box>
          </ScratchCard>
        </Stack>
        
      </Stack>
    </Stack>
  );
};

export default ScratchCardPage;
