const firebaseConfig = {
    apiKey: "AIzaSyDOQXIfauaNCUwmW9wauK-JO-kwywFR-_U",
    authDomain: "ryn-quiz.firebaseapp.com",
    projectId: "ryn-quiz",
    storageBucket: "ryn-quiz.appspot.com",
    messagingSenderId: "277280713854",
    appId: "1:277280713854:web:eb7acb3a4361ab56177c2f",
    measurementId: "G-GY9XGG6V31"
};



export default  firebaseConfig ;
