import React from "react";
import { useCallback } from "react";
import styles from "./CouponCard.css";
import ContentCopy from "../../assets/content-copy.svg";
import couponpic from "../../assets/couponpic.png";
import { copyToClipboard } from "../../utilities/general";

const CouponCard = (props) => {
  const onControlsButtonsClick = useCallback(() => {
    // Please sync "iPhone 13 Pro Max - 42" to the project
  }, []);

  return (
    <div>
      <div className="couponusedChild" />
      <img className="couponusedItem" alt="" src={props?.scratchCardObj?.imgUrl === ""? couponpic: props?.scratchCardObj?.imgUrl} />
      <div
        className="offerText"
        style={{
          color: "var(--ink-darkest, #090A0A)",
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "24px" /* 150% */,
          display:props?.scratchCardObj?.offerText===""?"none":"block"
        }}
      >
        {props?.scratchCardObj?.offerText}
      </div>
      <b
        className="offer"
        style={{
          color: "var(--ink-darkest, #090A0A)",
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "56px" /* 140% */,
        }}
      >
        {props?.scratchCardObj?.offer}
      </b>
      <div className="controlsButtons" onClick={onControlsButtonsClick} style={{display:props?.scratchCardObj?.couponCode===""?"none":"block" }}>
        <b
          className="text"
          style={{
            color: "#222",
            WebkitTextFillColor: "color(display-p3 0.1341 0.1341 0.1341)",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
          }}
        >
          {props?.scratchCardObj?.couponCode}
        </b>
        <img
          className="contentCopyIcon"
          alt=""
          src={ContentCopy}
          onClick={() => {
            copyToClipboard(props?.scratchCardObj?.couponCode);
          }}
          style={{display:props?.scratchCardObj?.couponCode===""?"none":"block" }}
        />
      </div>
    </div>
  );
};

export default CouponCard;
