import React from 'react'
import Stack from '@mui/material/Stack';
import AvatarMatch from '../AvatarMatch/AvatarMatch';
import { Box, Typography } from '@mui/material';
import JsonContext from '../../context/JsonContext';


const Match = () => {
  const {jsonData}  =  React.useContext(JsonContext)

  return (<>
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"inherit"}
        sx={{
          transform: "translateY(15px)",
          border: "5.56px solid rgba(255, 255, 255, 0.02)",
          borderRadius: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          zIndex: 0,
        }}
      >
        <Stack direction={"row"} justifyContent={'space-around'} alignItems={"center"} width={"inherit"}  sx={{ padding: "27px" }}>
          <AvatarMatch imgUrl={jsonData.avatar1}/>
          <Stack direction={"column"} >
            <Typography variant="h3" align='center' gutterBottom sx={{ color: "#FFF", fontFamily: "Inter", fontSize: "16px", fontStyle: "normal", fontWeight: "600", lineHeight: "20px" ,pb:2}}>
              TODAY
            </Typography>
            <Typography variant="h3" align='center' gutterBottom sx={{ color: "#FFF", fontFamily: "Inter", fontSize: "24px", fontStyle: "Bold", fontWeight: "700", lineHeight: "20px", pb:"36px" }}>
              VS
            </Typography>
          </Stack>
          <AvatarMatch imgUrl={jsonData.avatar2}/>
        </Stack>
      </Box>

  </>
  )
}

export default Match