import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ButtonAnimate from "../ButtonAnimate/ButtonAnimate";
import checkform from "../../assets/checkform.png";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Alert from "../Alert/Alert";
import ResponseContext from "../../context/responseContext";

const buttonContainerStyle = {
  position: "relative",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 1,
};

const UserForm = () => {
  const [focus,setFocus] = React.useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const { setResponseData } = React.useContext(ResponseContext);
  const [name, setname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [no, setNo] = React.useState("");
  const [check, setCheck] = React.useState(false);
  return (
    <>
      <Stack direction={"column"} alignItems={"center"}  marginY={"30px"} paddingTop={"40px"} marginX={"30px"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            transform: "translateY(15px)",
            border: "5.56px solid rgba(255, 255, 255, 0.02)",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            zIndex: 0,
            width: "100%",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            sx={{ position: "relative", top: "-63px" }}
          >
            <img src={checkform} height={"100px"} width={"126.92px"} alt="" />
          </Stack>

          <Box>
            <Stack
              component="form"
              width={"100%"}
              spacing={2}
              noValidate
              autoComplete="off"
              align="center"
              direction={"column"}
              sx={{ p: 2 }}
            >
              <TextField
                color="success"
                id="filled-hidden-label-normal-0"
                label="Name"
                variant="filled"
                sx={{ backgroundColor: "#FFF", borderRadius: "8px" }}
                onChange={(event) => {
                  setname(event.target.value);
                }}
                onBlur={()=>{setFocus(false);}}
                onFocus={()=>{setFocus(true);}}
              />
              <TextField
                color="success"
                id="filled-hidden-label-normal-1"
                label="Email"
                variant="filled"
                sx={{ backgroundColor: "#FFF", borderRadius: "8px" }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                onBlur={()=>{setFocus(false);}}
                onFocus={()=>{setFocus(true);}}
              />
              <TextField
                color="success"
                id="filled-hidden-label-normal-1"
                label="Whatsapp No"
                variant="filled"
                sx={{ backgroundColor: "#FFF", borderRadius: "8px" }}
                onChange={(event) => {
                  setNo(event.target.value);
                }}
                onBlur={()=>{setFocus(false);}}
                onFocus={()=>{setFocus(true);}}
              />
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  {...label}
                  sx={{
                color:"#FFF",
                '&.Mui-checked': {
                  color: "#FFF",
                }}}                
                  onChange={(event) => {
                    setCheck(event.target.checked);
                  }}
                />
                <Typography
                  variant="body"
                  gutterBottom
                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "100",
                    lineHeight: "normal",
                    px: 1,
                  }}
                >
                  You agree to our friendly privacy policy.
                </Typography>
              </Stack>
            </Stack>
            <Alert />
          </Box>
        </Box>
      </Stack>
      <Stack flexGrow="1"></Stack>
      <Stack direction={"row"}
        sx={{
            justifyContent: "center",
            marginBottom:"30px",
            display: focus===false ? "flex" : "none",
            flexDirection: "row",
            alignItems:"center",
        }}
        >
          <ButtonAnimate text={"Submit"} name={name} email={email} no={no} check={check}/>

        </Stack>
    </>
  );
};

export default UserForm;
