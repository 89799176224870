import { Stack } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ResponseContext from "../../context/responseContext";
import Alert from "../Alert/Alert";
import TextField from '@mui/material/TextField';


const CaroComp = (props) => {
  const { responseData, setResponseData } = React.useContext(ResponseContext);
  return (
    <Stack direction={"column"} alignItems={"center"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        // justifyContent={"center"}
        width={"inherit"}
        // height={"400px"}
      >
        <Typography
          fontFamily="Inter"
          fontWeight="700"
          fontSize="20px"
          lineHeight="normal"
          align="center"
          color="#D9D9D9"
          sx={{ my: "56px", p: "2px", mx: "60px" }}
        >
          {props?.obj?.question}
        </Typography>
        <Stack direction={"column"} spacing={2} sx={{ mx: 2, pb: 2 }}>
          {props?.obj?.options?.map((d, i) => {
            if (Object.keys(d)[0] !== "custom"){
            return (
              <Button
                variant="contained"
                style={{
                  color: ((responseData?.questions[props?.index]?.option===Object.keys(d)[0])?"#FFF":"#000"), backgroundColor: ((responseData?.questions[props?.index]?.option===Object.keys(d)[0])?"#840DB4":"#FFF"),
                  }}
                key={i}
                size="large"
                onClick={() => {
                  setResponseData((js) => {
                    let var_ = js.questions;
                    var_[props?.index] = {
                      id: props?.obj?.id,
                      question: props?.obj?.question,
                      option: Object.keys(d)[0],
                      answer: Object.values(d)[0],
                    };
                    return {
                      ...js,
                      questions: var_,
                    };
                  });
                }}
              >
                {Object.values(d)[0]}
              </Button>
            );}else{
              return(Object.values(d)[0]===""?<div key={i}></div>:
              !(responseData?.questions[props?.index]?.option==="custom")
              ?<Button
                variant="contained"
                style={{
                  color: ((responseData?.questions[props?.index]?.option===Object.keys(d)[0])?"#FFF":"#000"), backgroundColor: ((responseData?.questions[props?.index]?.option===Object.keys(d)[0])?"#840DB4":"#FFF"),
                  }}
                size="large"
                key={i}
                onClick={() => {
                  setResponseData((js) => {
                    let var_ = js.questions;
                    var_[props?.index] = {
                      id: props?.obj?.id,
                      question: props?.obj?.question,
                      option: Object.keys(d)[0],
                      answer: "",
                    };
                    return {
                      ...js,
                      questions: var_,
                    };
                  });
                }}
              >
                {Object.values(d)[0]}
              </Button>:
              
              <TextField
                focused
                color="success"
                id="filled-hidden-label-normal-0"
                label={Object.values(d)[0]}
                variant="filled"
                key={i}
                sx={{ backgroundColor: "#FFF", borderRadius: "4px" }}
                value={responseData.questions[props?.index]["answer"]}
                onChange={(event) => {
                  setResponseData((js) => {
                    let var_ = js.questions;
                    var_[props?.index] = {
                      id: props?.obj?.id,
                      question: props?.obj?.question,
                      option: Object.keys(d)[0],
                      answer: event.target.value,
                    };
                    return {
                      ...js,
                      questions: var_,
                    };
                  });

                }}
                
              />
              )
            }
          })}
          <Button
            variant="text"
            style={{ color: "#787878" }}
            sx={{ mt: 4 }}
            size="small"
          >
            <Typography variant="body2" fontSize={"10px"}>
              TAP AND CLICK NEXT TO WIN
            </Typography>

          
          </Button>
        </Stack>
        <Alert/>
      </Box>
    </Stack>
  );
};

export default CaroComp;
