import React from "react";
import { createContext,useState } from "react";

const alertContext = createContext({});

export function AlertProvider({children}){

    const [alert,setAlert] = useState({
        status:false,
        message:""
    });
    if(alert.status === true){
        setTimeout(()=>{
            setAlert((d)=>{
                return({
                    ...d,
                    "status":false
                })
            })
        }, 2000);
    }

    return(<alertContext.Provider value={{alert,setAlert}}>{children}</alertContext.Provider>)
}

export default alertContext;