import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import logo from "../assets/logo.png";
import TermsComp from "../components/TermsComp/TermsComp";
import Match from "../components/Match/Match";
import CaroComp from "../components/CaroComp/CaroComp";
import ButtonAnimate from "../components/ButtonAnimate/ButtonAnimate";
import Carousal from "../components/Carousal/Carousal";


const QuestionPage = () => {
  const [value, setValue] = React.useState(0);
  return (
    <Box
    direction="column"
    justifyContent="centre"
    alignItems="centre"
    sx={{
        display: "flex",
        flexDirection:"column"
    }}
    flexGrow={1}
>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="centre"
        padding="23px"
      >
        <img src={logo} alt="" height={"41px"} />
      </Stack>
      <Stack direction="column" marginX="30px" paddingTop={"4px"}>
        <Match />
        <Carousal setValue={setValue} value={value} />        
      </Stack>
      <Stack flexGrow="1"></Stack>

      <Stack direction={"row"}
        sx={{
            justifyContent: "center",
            marginBottom:"30px"
        }}
        >
            <ButtonAnimate text={"Next"} questionNo={value} setQuestionNo={setValue}/>
        </Stack>
    </Box>
  );
};

export default QuestionPage;
