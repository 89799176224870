import React from "react";
import { createContext,useState } from "react";

const ResponseContext = createContext({});

export function ResponseProvider({children}){

    const [responseData,setResponseData] = useState({
        user:{},
        questions:{
            "0":{id:""},
            "1":{id:""},
        },
        timeStamp:"",
        offerCode:"",
        hash:"",
    });


    return(<ResponseContext.Provider value={{responseData,setResponseData}}>{children}</ResponseContext.Provider>)
}

export default ResponseContext;