import React from "react";
import { createContext,useState } from "react";

const JsonContext = createContext({});

export function JsonProvider({children}){

    const [jsonData,setJsonData] = useState({});


    return(<JsonContext.Provider value={{jsonData,setJsonData}}>{children}</JsonContext.Provider>)
}

export default JsonContext;